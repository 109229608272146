// import { parseTime } from '@/plugins/lib/modules/date'
import { taskStatus, getLabel } from '@/utils/options';

export var columns = function columns(ctx) {return [
  {
    label: '序号',
    type: 'index',
    width: 60 },

  {
    label: '业务类型',
    kname: 'title' },

  {
    label: '工作项目',
    kname: 'source' },

  {
    label: '年月',
    kname: 'createTime' },

  {
    label: '积分',
    kname: 'createTime' },

  {
    label: '姓名',
    kname: 'createTime' },

  {
    label: '提交时间',
    kname: 'createTime' },

  {
    label: '状态',
    kname: 'status',
    filterFn: function filterFn(v) {
      return getLabel(taskStatus, v);
    } },

  {
    label: '操作',
    kname: 'btns',
    width: 160 }];};



export var searchOption = [
{
  kname: 'category',
  label: '状态：',
  component: {
    name: 'selector',
    props: {
      list: taskStatus,
      clearable: true } } },



{
  kname: 'tt',
  label: '月份：',
  component: {
    name: 'c-date-picker',
    props: {
      clearable: true,
      type: 'month' } } }];